<template>
	<div :class="['sticky-container', active ? 'active' : '']">
		<v-row justify="space-between" align="center">
			<v-col cols="12" md="5" v-if="!isMobile"></v-col>
			<v-col cols="12" md="7">
				<v-row>
					<v-col cols="12" md="6" v-if="!isMobile">
						<v-btn
							elevation="0"
							class="back-btn"
							block
							outlined
							large
							:disabled="backDisabled"
							@click="$emit('backClicked')">
							<span v-text="backLabel"></span>
						</v-btn>
					</v-col>
					<v-col cols="12" md="6">
						<v-btn
							elevation="0"
							class="next-btn"
							block
							large
							:disabled="nextDisabled"
							@click="$emit('nextClicked')">
							<span v-text="nextLabel"></span>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name : 'StickyBottomActions',
	props : {
		active : {
			type : Boolean,
			default : false,
		},
		nextLabel : {
			type : String,
			required : true,
		},
		nextDisabled : {
			type : Boolean,
			default : false,
		},
		backLabel : {
			type : String,
			required : true,
		},
		backDisabled : {
			type : Boolean,
			default : false,
		},
	},
	data() {
		return {
			windowWidth: window.innerWidth,
		};
	},
	computed: {
		isMobile() {
			return this.windowWidth <= 640;
		},
	},
	created() {
		window.addEventListener('resize', this.updateWindowWidth);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWindowWidth);
	},
	methods: {
		updateWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
	},
}
</script>


<style scoped lang="scss">
	.back-btn {
		background-color: $grey-lighter!important;
		color: $grey-dark;
	}
	.next-btn {
		background-color: $grey-dark !important;
		color: $grey-lighter!important;
		padding: 0;
	}
	.sticky-container {
		position: relative;
		background-color: white;
		margin-top: 0 !important;
		padding: 25px 30px;
	}
	.sticky-container.active{
		position: sticky;
		position: -webkit-sticky;
		bottom: 0%;
		z-index: 2;
		box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05);
	}
	.sticky-container .button-container v-btn {
		width: 50%;
	}
	@media all and (max-width: 620px) {
		.sticky-container .button-container button, .sticky-container .button-container a{
			font-size: 0.7rem;
		}
	}
</style>
