// @ts-nocheck
import Vue from "vue";

const DynamicPricingMixin = Vue.extend({
    computed : {
        dynamicPricingFfEnabled(): boolean {
            const { feature_flags } = this.$root.websiteConfig.data || {};
            return feature_flags?.includes('co-6205-ecom-reskin');
        },
    }
});


export default DynamicPricingMixin;