// @ts-nocheck
import Vue from "vue";
import globalVariables from "@/global";
import {
	BaseModel,
	CartItemModel,
	EventBus,
	ProductModel,
	EComService,
} from "@connectngo/sdk";
import InputNumeric from "@/components/InputNumeric";
import VExpandTransition from "vuetify/lib/components/transitions/expand-transition";
import VChip from "vuetify/lib/components/VChip";
import VAlert from "vuetify/lib/components/VAlert";
import moment from "moment";
import momentTZ from "moment-timezone";

const InputNumericLogic = Vue.extend({
	props: [
		"hasError",
		"readonly",
		"value",
		"min",
		"max",
		"title",
		"increment",
		"loading",
		"hasQuantity",
		"large",
	],
	components: { InputNumeric, VExpandTransition, VChip, VAlert },
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},
	template: `
		<div>
			<div v-if="title" class="d-flex align-center mb-1">
				<label class="font-weight-bold text-capitalize-fl" v-text="title"></label>
				<span v-if="hasError" class="error--text">&nbsp;*</span>
			</div>

			<InputNumeric
				v-if="!readonly"
				v-model="_value"
				:min="min > 0 ? min : 0"
				:max="max > 0 ? max : null"
				:increment="increment"
				:readonly="readonly || loading"
				:large="large"
			/>

			<v-chip v-else class="px-6 title font-weight-black" large v-text="value"></v-chip>

			<!-- QUANTITY -->
			<div v-if="hasQuantity" class="d-flex align-center text-no-wrap">
				<div class="text-right" style="flex: 1">
					<span v-if="min === null && max === null" v-text="$t('product.quantity')"></span>
					<span v-else-if="min !== null && max !== null" v-text="$t('product.quantityBoth', { min, max })"></span>
					<span v-else-if="min !== null && max === null" v-text="$t('product.quantityMin', { min })"></span>
					<span v-else v-text="$t('product.quantityMax', { max })"></span>
				</div>
			</div>

			<!-- MIN/MAX ERRORS -->
			<v-expand-transition>
				<v-alert v-if="min !== null && min > _value" color="error" class="ma-0 mt-2" text dense>
					<span v-text="$tc('product.error.needAtLeast', min, { quantity: min })"></span>
				</v-alert>
			</v-expand-transition>
			<v-expand-transition>
				<v-alert v-if="max !== null && _value > max" color="error" class="ma-0 mt-2" text dense>
					<span v-text="$tc('product.error.atMaximum', max, { quantity: max })"></span>
				</v-alert>
			</v-expand-transition>
		</div>
	`,
});

const ProductMixin = {
	props: {
		product: {
			required: true,
			type: ProductModel,
			default: () => new ProductModel(),
		},
		unitPrice: {
			type: Number,
			default: null,
		},
		min: {
			type: Number,
			default: 0,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		basic: {
			type: Boolean,
			default: false,
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		minimal: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		enhanceText: {
			type: String,
			default: null,
		},
		cartItem: {
			type: CartItemModel,
			default: () => new CartItemModel(),
		},
		inCart: {
			type: Boolean,
			default: false,
		},
		eventId: {
			type: Number,
			default: null,
		},
		isCrossSell: {
			type: Boolean,
			default: false,
		},
		showCalendar: {
			type: Boolean,
			default: true,
		},
		daySelected: {
			type: String,
			default: null,
		},
		productType: {
			type: String,
			default: null,
		},
		displayPrice: {
			type: Boolean,
			default: true,
		},
	},

	data: () => ({
		loading: false,
		loadingModifiers: false,
		quantity: 0,
		actualQuantity: 0,
		selectedModifiers: {},
		quantityModifiers: {},
		infoModifiers: {},
		modifiers: new BaseModel({
			event_groups: [],
		}),
		imageAspect: 1,
		modifiersByEventGroups: [],
		hasSelectedDate: false,
		selectedModifierEvent: {},
		slots: [],
		eventClosed: false,
		available: true,
		crossSellEventId: null,
		selectedEventID: null,
		formErrors: {},
		timer: null,
	}),

	computed: {
		_selected: {
			get() {
				return this.selected;
			},
			set(selected) {
				this.$emit("update:selected", selected);
			},
		},
		_eventId: {
			get() {
				const cartItemId = this.cartItem.data.event
					? this.cartItem.data.event.id
					: null;
				const eventId = this.isCrossSell
					? this.crossSellEventId
					: (this.eventId || this.selectedEventID) ?? cartItemId;

				if (null === eventId) {
					//no need to fetch the api if event id is null priceVerifiedViaApi
					this.product.data.priceVerifiedViaApi = true;
				}

				return eventId;
			},
			set(eventId) {
				if (this.isCrossSell) {
					this.crossSellEventId = eventId;
				} else {
					this.$emit("update:eventId", eventId);
				}
			},
		},
		canSelect() {
			return (
				!this.loading &&
				!this.readonly &&
				!this.eventClosed &&
				this.available
			);
		},
		isAvailable() {
			return this.available;
		},
		promotion() {
			return this.product.data.promotion;
		},
		hasModifiers() {
			// return this.product.data.has_modifiers;
			return (
				this.cartItem.data.modifiers.length > 0 ||
				this.product.data.has_modifiers ||
				this.product.data.modifiers.length > 0
			);
		},
		hasModifiersInCart() {
			return (
				this.hasModifiers &&
				Object.keys(this.actualQuantityModifiers).length > 0
			);
		},
		actualQuantityModifiers() {
			const object = {};
			if (this.inCart) {
				this.cartItem.data.modifiers.forEach((cartModifier) => {
					object[cartModifier.data.product.data.id] =
						cartModifier.data.quantity;
				});
			}
			return object;
		},
		eventGroupId() {
			return this.product.data.event_group?.id;
		},
		hasQuantity() {
			return this._min !== null && this._max !== null;
		},
		_min() {
			return this.product.data.min_quantity;
		},
		_max() {
			return this.product.data.max_quantity;
		},
		hasError() {
			return (
				(this._min !== null && this._min > this.quantity) ||
				(this._max !== null && this.quantity > this._max)
			);
		},
	},

	created() {
		//we will load the modifier when we will select the first event
		const hasEvent = this.product.data.event_group && this.product.data.event_group.id !== null;
		if (this.hasModifiers && !hasEvent) {
			this.loadModifiers();
		}
		// this.product.data.modifiers.forEach((modifier, index) => {
		// 	this.selectedModifiers[modifier.data.id] = false;
		// });

		const image = new Image();
		image.src = this.product.data.image;
		image.onload = () => {
			this.imageAspect = image.width / image.height;
		};

		if (this.inCart) {
			this.quantity = this.cartItem.data.quantity;
			this.actualQuantity = this.cartItem.data.quantity;

			if (this.cartItem.data.event) {
				this._eventId = this.cartItem.data.event.id;
			}
		} else {
			if (this.product.data.increment > this.product.data.min_quantity) {
				this.quantity = this.product.data.increment;
			} else {
				this.quantity = this.product.data.min_quantity ?? 1;
			}
		}
	},

	mounted() {
		if (this.product.data.event_group?.id) {
			EventBus.subscribe(
				"eventGroupState" + this.product.data.event_group.id,
				this.handleEventSelected
			);
			EventBus.subscribe(
				"timeSlotPickedForGroup" + this.product.data.event_group.id,
				this.handleSlotSelected
			);
		}
	},

	methods: {
		/* Google Tag Manager */
		triggerGAAddEvent(quantity) {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "add_to_cart",
					ecommerce: {
						items: [
							{
								item_id: this.product.data.id,
								item_name: this.$options.filters.translatable(
									this.product.data.name,
									this.product.data.name_i18n,
									this.$i18n.locale
								),
								currency:
									this.$root.websiteConfig.data.tenant.currency
										.code,
								index: 1,
								price: this.product.data.price,
								quantity: quantity,
							},
						],
					},
				});
			}
		},

		triggerGARemoveEvent(quantity) {
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "remove_from_cart",
					ecommerce: {
						items: [
							{
								item_id: this.product.data.id,
								item_name: this.$options.filters.translatable(
									this.product.data.name,
									this.product.data.name_i18n,
									this.$i18n.locale
								),
								currency:
									this.$root.websiteConfig.data.tenant.currency
										.code,
								index: 1,
								price: this.product.data.price,
								quantity: quantity,
							},
						],
					},
				});
			}
		},

		loadModifiers(eventId, quantityModifiersNoUpdate) {
			const productId =
				this.product.data.id ?? this.cartItem.data.product.data.id;
			this.loadingModifiers = true;

			return Promise.all([
				new EComService().getProductModifiers(productId, this._eventId),
			])
				.then(([productModifiers]) => {
					this.modifiers = productModifiers;
					this.initModifiers(quantityModifiersNoUpdate);

					this.modifiersByEventGroups =
						productModifiers.data.event_groups;
					this.calendarForModifiers =
						productModifiers.data.show_calendar;

					this.getProductPrices(eventId);
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loadingModifiers = false));
		},


		handleAddClick() {
			this.loading = true;
			this.addProductToCart(this.quantity);
		},

		handleApplyChanges(reloadWalletId = null) {
			this.loading = true;
			if (this.quantity > this.actualQuantity) {
				this.addProductToCart(this.quantity - this.actualQuantity);
			} else if (this.quantity < this.actualQuantity) {
				this.removeProductToCartApiCall(
					this.actualQuantity - this.quantity,
					reloadWalletId
				);
			}
			this.updateModifiersInCart();
		},

		handleModifierClick(guid) {
			this.selectedModifiers[guid] = !this.selectedModifiers[guid];
			this.$forceUpdate();
		},

		handleEventSelected(eventInfo) {
			this.eventClosed = eventInfo.eventGroupState === "closed";
		},

		handleSlotSelected(slot) {
			if (!this.product.data.unavailable_before) {
				this.available = true;
				return;
			}

			this.available = slot.from.isAfter(
				moment.unix(this.product.data.unavailable_before)
			);
		},

		handleRemoveClick(quantity, walletId = null) {
			this.$confirm(
				this.$i18n.t("product.removeConfirm.title"),
				this.$i18n.t("product.removeConfirm.body"),
				this.$i18n.t("btn.remove"),
				(modal, btn) => {
					btn.attrs.loading = true;
					modal.disabled = true;
					this.removeProductToCartApiCall(quantity, walletId)
						.then(() => {
							modal.visible = false;
						})
						.catch(this.$handleError)
						.finally(() => (btn.attrs.loading = false));
				},
				{
					dark: true,
				}
			);
		},

		initModifiers(quantityModifiersNoUpdate) {
			this.selectedModifierEvent = {};
			this.infoModifiers = {};
			if (!quantityModifiersNoUpdate) {
				this.quantityModifiers = {};
			}
			this.modifiers.data.event_groups.forEach((eventGroup) => {
				this.selectedModifierEvent[eventGroup.guid] = null;
				eventGroup.data.modifiers.forEach((modifier) => {
					if (modifier.data.checkbox) {
						this.selectedModifiers[modifier.data.id] =
							!!modifier.data.auto_selected;
					}

					this.infoModifiers[modifier.data.id] = {
						productId: modifier.data.id,
						checkbox: modifier.data.checkbox,
						price: modifier.data.price,
						eventGroupKey: eventGroup.guid,
						required: modifier.data.required,
					};
					if (this.inCart) {
						this.cartItem.data.modifiers.forEach((cartModifier) => {
							if (
								cartModifier.data.product.data.id ===
								modifier.data.id
							) {
								setTimeout(() => {
									this.infoModifiers[
										modifier.data.id
									].productGroup =
										cartModifier.data.productGroup;
									this.quantityModifiers[modifier.data.id] =
										cartModifier.data.quantity;
									if (
										modifier.data.checkbox &&
										cartModifier.data.quantity > 0
									) {
										this.selectedModifiers[
											modifier.data.id
										] = true;
									}
									this.selectedModifierEvent[
										eventGroup.guid
									] = cartModifier.data.hasEvent
										? cartModifier.data.event.id
										: null;
								});
							}
						});
					}
				});
				if ((eventGroup.data.events || []).length === 1) {
					this.handleModifierSelectTimeSlotClick(
						eventGroup.data.events[0],
						eventGroup.guid
					);
				}
			});
		},

		subTotal() {
			return (
				(this.unitPrice || this.product.data.price || 0) *
				(this.quantity || 0)
			);
		},

		totalPrice() {
			let totalModifiers = 0;
			this.modifiers.data.event_groups.forEach((eventGroup) => {
				eventGroup.data.modifiers.forEach((modifier) => {
					if (
						!modifier.data.checkbox ||
						this.selectedModifiers[modifier.data.id]
					) {
						totalModifiers +=
							(modifier.data.price || 0) *
							(this.quantityModifiers[modifier.data.id] || 0) *
							(this.quantity || 1);
					}
				});
			});
			return this.subTotal() + totalModifiers;
		},

		total(price, quantity) {
			return price * quantity;
		},

		addProductToCart(quantity) {
			const modifiers = [];
			Object.keys(this.quantityModifiers).forEach((modifier_key) => {
				if (
					this.quantityModifiers[modifier_key] > 0 ||
					this.selectedModifiers[modifier_key]
				) {
					const modifierTemp = {
						productId: this.infoModifiers[modifier_key].productId,
						quantity: this.infoModifiers[modifier_key].checkbox
							? this.selectedModifiers[modifier_key]
								? 1
								: 0
							: this.quantityModifiers[modifier_key],
						eventId:
							this.selectedModifierEvent[
								this.infoModifiers[modifier_key].eventGroupKey
							],
					};

					if (this.infoModifiers[modifier_key].price !== null) {
						modifierTemp.price =
							this.infoModifiers[modifier_key].price;
					}

					modifiers.push(modifierTemp);
				}
			});

			this.addProductToCartApiCall(quantity, modifiers);
		},

		addProductToCartApiCall(quantity, modifiers = []) {
			new EComService()
				.addProductToCart(
					this.product.data.id,
					quantity,
					this._eventId,
					this.product.data.price,
					modifiers
				)
				.then((model) => {
					this.formErrors = {};
					this.$snack(this.$i18n.t("product.cartUpdated"));
					this.updateCart(model);
					this.triggerGAAddEvent(quantity);
					this.getAvailableCombos();
				})
				.catch((reason) =>
					this.$handleError(this, reason, this.formErrors)
				) // need to add support for inexistant cart id
				.finally(() => {this.loading = false; this.product.data.priceVerifiedViaApi = true});
		},

		getAvailableCombos() {
			return new EComService()
				.getAvailableCombos()
				.then((combos) => {
					EventBus.publish("COMBO_UPDATED", combos.results);
				})
				.catch((reason) => {
					if (reason.response && reason.response.status !== 404) {
						Vue.prototype.$snack(reason.messages.error, "error");
					}
					EventBus.publish("COMBO_UPDATED", []);
				});
		},

		reset() {
			this.quantity = 0;
			this.actualQuantity = 0;
			this.initModifiers();
		},

		removeProductToCartApiCall(quantity, walletId = null) {
			const items = [
				{
					productId: this.product.data.id,
					quantity: quantity,
					productGroup: this.cartItem.data.productGroup,
					reloadWalletId : walletId
				},
			];
			return new EComService()
				.removeProductsFromCart(items)
				.then((model) => {
					this.$snack(this.$i18n.t("product.cartUpdated"));
					this.updateCart(model);
					this.triggerGARemoveEvent(quantity);
					this.getAvailableCombos();
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},

		updateCart(model) {
			for (let index = 0; index < model.data.items.length; index++) {
				if (
					model.data.items[index].data.productGroup ===
					this.product.data.product_group
				) {
					this.actualQuantity = model.data.items[index].data.quantity;
					this.quantity = model.data.items[index].data.quantity;
					break;
				}
			}

			globalVariables.cart = model;
			EventBus.publish("CART_UPDATED", model);
		},

		handleModifierSelectTimeSlotClick(slot, guid) {
			this.selectedModifierEvent[guid] = slot.id;
			this.$forceUpdate();
		},

		canAddToCart() {
			if (this.loading) {
				return false;
			}

			if(this.product.data.price === null) {
				return false;
			}

			if (
				this.quantity === 0 ||
				(this.product.data.min_quantity > 0 &&
					this.quantity < this.product.data.min_quantity) ||
				(this.product.data.max_quantity > 0 &&
					this.quantity > this.product.data.max_quantity)
			) {
				return false;
			}

			if (this.eventClosed) {
				return false;
			}

			if (!this.available) {
				return false;
			}

			if (this.product.data.event_group.id !== null) {
				if (!this.inCart && this._eventId === null) {
					return false;
				}

				if (this.hasModifiers) {
					if (!this.eventSelectedForModifiers()) {
						return false;
					}
				}
			}

			if (this.hasModifiers) {
				if (!this.requiredModifiersSelected()) {
					return false;
				}

				if (
					this.inCart &&
					this.quantity === this.actualQuantity &&
					!this.modifierChanged()
				) {
					return false;
				}

				if (this.quantity === 0) {
					return false;
				}
			} else {
				if (this.inCart) {
					if (this.quantity === this.actualQuantity) {
						return false;
					}
				}
			}

			return true;
		},

		requiredModifiersSelected() {
			let required = true;
			Object.keys(this.infoModifiers).forEach((modifierKey) => {
				if (this.infoModifiers[modifierKey].required) {
					if (
						!this.quantityModifiers[modifierKey] ||
						this.quantityModifiers[modifierKey] === 0
					) {
						required = false;
					}
				}
			});
			return required;
		},

		eventSelectedForModifiers() {
			let selected = true;
			for (let i = 0; i < this.modifiers.data.event_groups.length; i++) {
				const eventGroup = this.modifiers.data.event_groups[i];
				for (let j = 0; j < eventGroup.data.modifiers.length; j++) {
					const modifier = eventGroup.data.modifiers[j];
					if (
						this.quantityModifiers[modifier.data.id] !== 0 &&
						eventGroup.data.has_event_group
					) {
						if (
							this.infoModifiers[modifier.data.id] &&
							this.selectedModifierEvent[
								this.infoModifiers[modifier.data.id]
									.eventGroupKey
							] === null
						) {
							selected = false;
						}
					}
				}
			}
			return selected;
		},

		modifierChanged() {
			let changed = false;
			Object.keys(this.infoModifiers).forEach((modifierKey) => {
				const actualModQty =
					this.actualQuantityModifiers[modifierKey] ?? 0;
				if (this.infoModifiers[modifierKey].checkbox) {
					if (
						(this.selectedModifiers[modifierKey] &&
							actualModQty === 0) ||
						(!this.selectedModifiers[modifierKey] &&
							actualModQty !== 0)
					) {
						changed = true;
					}
				} else {
					if (this.quantityModifiers[modifierKey] !== actualModQty) {
						changed = true;
					}
				}
			});

			return changed;
		},

		updateModifiersInCart() {
			const modToAdd = [],
				modToRemove = [],
				promises = [];

			Object.keys(this.infoModifiers).forEach((modifierGuid) => {
				const actualModQty =
					this.actualQuantityModifiers[modifierGuid] ?? 0;
				if (this.quantityModifiers[modifierGuid] > actualModQty) {
					modToAdd.push({
						productId: this.infoModifiers[modifierGuid].productId,
						quantity:
							this.infoModifiers[modifierGuid].checkbox &&
							this.selectedModifiers[modifierGuid]
								? 1
								: this.quantityModifiers[modifierGuid] - actualModQty,
						eventId: this.selectedModifierEvent[this.infoModifiers[modifierGuid].eventGroupKey],
						parentProductGroup: this.cartItem.data.productGroup,
					});
				} else if (
					this.quantityModifiers[modifierGuid] < actualModQty
				) {
					modToRemove.push({
						productId: this.infoModifiers[modifierGuid].productId,
						quantity:
							this.infoModifiers[modifierGuid].checkbox &&
							!this.selectedModifiers[modifierGuid]
								? 1
								: actualModQty - this.quantityModifiers[modifierGuid],
						eventId:
							this.selectedModifierEvent[
								this.infoModifiers[modifierGuid].eventGroupKey
							],
						productGroup:
							this.infoModifiers[modifierGuid].productGroup,
						parentProductGroup: this.cartItem.data.productGroup,
					});
				}
			});

			if (modToAdd.length > 0) {
				promises.push(new EComService().addModifiers(modToAdd));
			}

			if (modToRemove.length > 0) {
				promises.push(
					new EComService().removeProductsFromCart(modToRemove)
				);
			}

			if (promises.length > 0) {
				Promise.all(promises)
					.then((response) => {
						this.$snack(this.$i18n.t("product.cartUpdated"));
						let model;
						if (response.length > 1) {
							model = response.reduce((prev, current) => {
								if (
									moment(prev.data.updated_at).isAfter(
										moment(current.data.updated_at)
									)
								) {
									return prev;
								} else {
									return current;
								}
							});
						} else {
							model = response[0];
						}

						this.updateCart(model);
						this.loading = false;
					})
					.finally(() => (this.loading = false));
			}
		},
		getEventAvailabilities(from_date, to_date) {
			this.loading = true;
			return new EComService()
				.getEventAvailabilities(eventGroupId, from_date, to_date)
				.then((response) => {
					this.slots = response.flat();

					if (!this.value) {
						// this.value = this.slots[0];
					}
				})
				.finally(() => (this.loading = false));
		},

		getProductPrices(eventId) {
			this.loading = true;
			// Assemble product IDs from parent product and modifiers
			const productIds = [this.product.data.id];

			if (this.hasModifiers) {
				this.modifiers.data.event_groups.forEach((eventGroup) => {
					this.selectedModifierEvent[eventGroup.guid] = null;
					eventGroup.data.modifiers.forEach((modifier) => {
						productIds.push(modifier.data.id);
					});
				});
			}

			return new EComService()
				.getProductPrices(eventId, productIds)
				.then((response) => {
					// Assign updated prices to product and modifiers
					if (
						response.data[this.product.data.id] &&
						response.data[this.product.data.id].min_price !==
							this.product.data.price
					) {
						this.product.data.price =
							response.data[this.product.data.id].min_price;
					}

					if (response.data[this.product.data.id] === null) {
						this.product.data.price = null;
					}

					if (this.hasModifiers) {
						this.modifiers.data.event_groups.forEach(
							(eventGroup) => {
								this.selectedModifierEvent[eventGroup.guid] = null;
								eventGroup.data.modifiers.forEach(
									(modifier) => {
										if (
											response.data[modifier.data.id] &&
											response.data[modifier.data.id].min_price !== modifier.data.price
										) {
											modifier.data.price = response.data[modifier.data.id].min_price;
										}
									}
								);
							}
						);

						for (const key in this.infoModifiers) {
							if (response.data[key]) {
								this.infoModifiers[key].price = response.data[key].min_price;
							}
						}

					}
				})
				.finally(() => {this.loading = false; this.product.data.priceVerifiedViaApi = true;});
		},
		dateToTenantTimezone(date = new Date()) {
			const now = momentTZ(date);
			const serverOffset = now.utcOffset();
			now.tz(this.$root.websiteConfig.data.tenant.timezone);

			const localOffset = now.utcOffset();
			const diffInMinutes = serverOffset - localOffset;
			const tenantDate = moment(date);
			tenantDate.add(-diffInMinutes, "minutes");

			return tenantDate.clone();
		},
		sortEvents(events, selectedDate) {
			let sortedEvents = [];
			events?.forEach((slot) => {
				const slotMoment = this.dateToTenantTimezone(
					new Date(slot.end_ts * 1000)
				);
				if (slotMoment.format("YYYY-MM-DD") === selectedDate) {
					sortedEvents.push(slot);
				}
			});
			sortedEvents = [...new Set(sortedEvents)];
			return sortedEvents;
		},
	},
	watch: {
		eventId: {
			deep: true,
			handler(value) {
				if (this.hasModifiers) {
					this.loadModifiers(value);
				} else {
					this.getProductPrices(value);
				}
			},
		},
		selectedEventID: {
			deep: true,
			handler(value) {
				this.product.data.priceVerifiedViaApi = false;
				if (value && this.hasModifiers) {
					this.loadModifiers(value, true);
				} else {
					this.getProductPrices(value);
				}
			},
		},
	},
};

const WindowWidthMixin = {
	data: () => ({
		isMobile: false,
		isIpad: false,
		windowWidth: String,
		windowHeight: String,
	}),

	beforeDestroy() {
		if (typeof window === "undefined") return;
		window.removeEventListener("resize", this.onResize, { passive: true });
	},

	mounted() {
		this.onResize();
		window.addEventListener("resize", this.onResize, { passive: true });
	},

	methods: {
		onResize() {
			this.isIpad = window.innerWidth <= 1024;
			this.isMobile = window.innerWidth < 600;
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
	},
};

const DebounceMixin = {
	data: () => ({
		timer: null,
	}),

	beforeDestroy() {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = null;
		}
	},

	methods: {
		debounce(fn, wait) {
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				fn();
			}, wait);
		},
		isSlotIdDisabled(slotId,disabledSlot) {
			return disabledSlot.length === 0 || !disabledSlot.includes(slotId);
		},
	},
};

export default ProductMixin;

export { InputNumericLogic, WindowWidthMixin, DebounceMixin };
