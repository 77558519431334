<template>
	<v-app :style="appStyle" :class="{'dynamic-pricing-ff-on' : dynamicPricingFfEnabled}">
		<!-- LOADING -->
		<v-overlay fixed v-model="loading">
			<v-progress-circular :size="64" indeterminate></v-progress-circular>
		</v-overlay>

		<!-- GENERAL COMPONENTS -->
		<ErrorHandler :response="$root.error"></ErrorHandler>
		<Snack :snack="$root.snack" top />
		<Modal
			v-model="$root.modal.visible"
			:title="$root.modal.title"
			:body="$root.modal.body"
			:buttons="$root.modal.buttons"
			v-bind="$root.modal.attrs"
			scrollable
		/>

		<!-- LAYOUT -->
		<v-main class="systemLight pa-print-0 d-block">
			<Toolbar
				:social-links="_socialLinks"
				:links="_links"
				:is_b2b="is_b2b"
				app
				fixed
				heading
			/>
			<router-view style="flex: 1" :key="$route.fullPath"></router-view>
			<Footer
				:social-links="_socialLinks"
				:links="_links"
				class="footer"
				:is_b2b="is_b2b"
				absolute
			/>
		</v-main>
	</v-app>
</template>

<script>
import Vue from "vue";
import ErrorHandler from "@/components/ErrorHandler.vue";
import Snack from "@/components/Snack.vue";
import Modal from "@/components/Modal.vue";
import Toolbar from "@/components/Toolbar";
import Footer from "@/components/Footer";
import CookieBanner from "@/components/CookieBanner";
import globalVariables from "@/global";
import { EComService, Cookies, EventBus, AccountModel } from "@connectngo/sdk";
import VueGtm from "@gtm-support/vue2-gtm";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import InteractiveMapMixin from "@/mixins/InteractiveMapMixin";
import * as Sentry from "@sentry/vue";
import DynamicPricingMixin from '@/mixins/DynamicPricingMixin'

const middlewares = [
	{
		name: "restricted",
		handler: (route) => {
			return globalVariables.user.data.id !== null && !globalVariables.user.data.is_guest;
		},
	},
	{
		name: "anonymous",
		handler: (route) => {
			return globalVariables.user.data.id === null || globalVariables.user.data.is_guest;
		},
	},
	{
		name: 'co-5754-time-based-combos',
		handler: (route) => {
			const { feature_flags } = globalVariables.websiteConfig.data || {};
			return feature_flags?.includes('co-5754-time-based-combos') && feature_flags?.includes('co-5739-cart-enhancement');
		}
	},
  {
    name: 'co-7601-reload-flow-v2',
    handler: (route) => {
      const { feature_flags, fields } = globalVariables.websiteConfig.data || {};
      return feature_flags?.includes('co-7601-reload-flow-v2') && fields?.active_reload === "1";
    }
  },
  {
    name: 'with-reload-wallet',
    handler: (route) => {
      return globalVariables.cart.data.reload_wallet_id !== null;
    }
  },
	{
		name: 'co-6205-ecom-reskin',
		handler: (route) => {
			const { feature_flags } = globalVariables.websiteConfig.data || {};
			return feature_flags?.includes('co-6205-ecom-reskin');
		}
	},
];

let openPageEventBus;
let askLogoutEventBus;
let logOutUser;
let addToCardEventBus;
let b2bLoginEvent;

export default Vue.extend({
	name: "App",
	metaInfo() {
		return {
			titleTemplate:
				"%s | " +
				this.$options.filters.translatable(
					this.$root.websiteConfig.data.name,
					this.$root.websiteConfig.data.name_i18n,
					this.$i18n.locale
				),
			meta: [
				{
					property: "description",
					content: this.$options.filters.translatable(
						this.$root.websiteConfig.data.name,
						this.$root.websiteConfig.data.name_i18n,
						this.$i18n.locale
					),
				},
				{ property: "og:title", content: this.$i18n.t("route.home") },
				{ property: "og:type", content: "product" },
				{ property: "og:url", content: window.location.href },
				{
					property: "og:description",
					content: this.$options.filters.translatable(
						this.$root.websiteConfig.data.name,
						this.$root.websiteConfig.data.name_i18n,
						this.$i18n.locale
					),
				},
				{
					property: "og:image",
					content:
						this.$root.websiteConfig.data.images.length === 0
							? ""
							: this.$root.websiteConfig.data.images[
									"logo-header"
							  ],
				},
				{
					property: "twitter:image:src",
					content:
						this.$root.websiteConfig.data.images.length === 0
							? ""
							: this.$root.websiteConfig.data.images[
									"logo-header"
							  ],
				},
				{
					property: "og:site_name",
					content: this.$options.filters.translatable(
						this.$root.websiteConfig.data.name,
						this.$root.websiteConfig.data.name_i18n,
						this.$i18n.locale
					),
				},
			],
		};
	},
	components: { ErrorHandler, Snack, Toolbar, Modal, Footer, CookieBanner },

	mixins: [InteractiveMapMixin, DynamicPricingMixin],

	data: () => ({
		loading: false,
		cookieConsent: null,
		interval: null,
	}),
	mounted() {
		this.watchLocalStorage();
	},
	computed: {
		is_b2b() {
			if (globalVariables.user.data.id == null) {
				return false;
			}
			return globalVariables.user.data.salesgroup_id !== null ? true : false;
		},
		_socialLinks() {
			return this.$root.websiteConfig.data.menus.social
				.map((item) => {
					const href = this.$options.filters.translatable(
						null,
						item.url_i18n,
						this.$i18n.locale
					);
					return {
						...item,
						href,
						label: this.$options.filters.translatable(
							item.label,
							item.label_i18n,
							this.$i18n.locale
						),
					};
				})
				.filter((item) => item.href);
		},
		_links() {
			return this.$root.websiteConfig.data.menus.primary
				.map((item) => {
					const href = this.$options.filters.translatable(
						null,
						item.url_i18n,
						this.$i18n.locale
					);
					const slug = this.$options.filters.translatable(
						null,
						item.slug_i18n,
						this.$i18n.locale
					);
					return {
						to: slug ? "/" + this.$i18n.locale + "/" + slug : null,
						href: href,
						target: href ? "_blank" : null,
						label: this.$options.filters.translatable(
							item.label,
							item.label_i18n,
							this.$i18n.locale
						),
					};
				})
				.filter((item) => item.label && (item.to || item.href));
		},
		appStyle() {
			return {
				backgroundAttachment: "fixed",
				backgroundPosition: "center center",
				backgroundRepeat:
					this.$root.websiteConfig.data.fields.repeat_background ===
					"1"
						? ""
						: " no-repeat",
				backgroundSize:
					this.$root.websiteConfig.data.fields.repeat_background ===
					"1"
						? null
						: "cover",
				backgroundImage:
					this.$root.websiteConfig.data.fields.background === "1"
						? "url(" +
						  this.$root.websiteConfig.data.images.background +
						  ")"
						: null,
			};
		},
	},
	methods: {
		watchLocalStorage() {
			this.interval = setInterval(() => {
				this.cookieConsent = window.localStorage.getItem('gdpr_cookie_consent');
			}, 1000);
		},
		load() {
			this.runMiddlewares();
			this.loading = true;

			const interactiveMap = this.$root.websiteConfig.data.interactive_map;
			if(interactiveMap) {
				this.getMapItemProductIds(interactiveMap.children);
				globalVariables.interactiveMapProductIds = this.interactiveMapProductIds;
			}

			if (this.is_b2b) {
				return Promise.all([new EComService().getB2bTags()])
					.then(([tagsResponse]) => {
						this.$root.tags = tagsResponse;
					})
					.catch((reason) => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			} else {
				return Promise.all([new EComService().getTags()])
					.then(([tagsResponse]) => {
						this.$root.tags = tagsResponse;
            this.$root.reloadTag = tagsResponse.results.find(tag => tag.data.reloadable)
					})
					.catch((reason) => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			}

		},
		update() {
			this.runMiddlewares();

			this.$root.accountLinks = [
				{
					key: "account",
					icon: "mdi-home-outline",
					to: "/" + this.$i18n.locale + "/account",
					exact: true,
					classes: {
						"v-list-item--active":
							this.$route.name === "account_section" &&
							this.$route.params.section === "edit",
					},
				},
				{
					key: "connected",
					icon: "mdi-account-group-outline",
					to: "/" + this.$i18n.locale + "/account/connected",
				},
				{
					key: "wallet",
					icon: "mdi-pound",
					to: "/" + this.$i18n.locale + "/account/wallet",
				},
				{
					key: "reschedule",
					icon: "mdi-calendar",
					to: "/" + this.$i18n.locale + "/account/reschedule",
				},
				{
					key: "transactions",
					icon: "mdi-file-outline",
					to: "/" + this.$i18n.locale + "/account/transactions",
				},
				{
					key: "logout",
					icon: "mdi-logout-variant",
					events: {
						click: () => {
							EventBus.publish("ASK_TO_LOGOUT");
						},
					},
				},
			];

			if (this.$root.websiteConfig.data.experiential_module) {
				this.$root.accountLinks.splice(3, 0, {
					key: "memories",
					icon: "mdi-account-box-multiple-outline",
					to: "/" + this.$i18n.locale + "/account/memories",
				});
			}

      const index = this.$root.accountLinks.findIndex(
        (data) => data.key === "transactions"
      );
      this.$root.accountLinks.splice(index + 1, 0, {
        key: "reservations",
        icon: "mdi-calendar-plus",
        to: "/" + this.$i18n.locale + "/account/reservations",
      });
		},
		runMiddlewares() {
			let route = this.$route.matched.find(
				(route) => route.name === this.$route.name
			);
			this.$route.params.is_b2b = this.is_b2b;
			while (route) {
				const routeMiddlewares = route.meta.middlewares || [];
				for (let i = 0; i < routeMiddlewares.length; i++) {
					const middleware = middlewares.find(
						(middleware) => middleware.name === routeMiddlewares[i]
					);
					const answer = middleware.handler(route);
					if (answer !== true && answer !== undefined) {
						return this.$router.replace(
							answer === false ? { name: "home" } : answer
						);
					}
				}
				route = route.parent;
			}
		},
	},

	created() {
		this.load();
		EventBus.subscribe("CART_UPDATED", (cart) =>
			Object.assign(this.$root.cart.data, cart.data)
		);
		let ref = this;
		EventBus.subscribe("COMBO_UPDATED", function (combos) {
			ref.$root.combos = combos;
		});

		this.$router.onError((error) => {
			this.$handleError(this, error);
		});

		openPageEventBus = EventBus.subscribe("OPEN_PAGE", (type) => {
			const link = this.$root.websiteConfig.data.menus.primary.find(
				(link) => link.type === type
			);
			if (link) {
				const win = window.open(
					this.$options.filters.translatable(
						null,
						link.url_i18n,
						this.$i18n.locale
					),
					"_blank"
				);
				win.focus();
			}
		});

		addToCardEventBus = EventBus.subscribe("ADD_TO_CARD", (product) => {
			this.$root.cart.addProduct(product);
		});

		logOutUser = EventBus.subscribe("RES_LOGOUT", () => {
			new EComService().logout().finally(() => {
				this.$root.user.data = new AccountModel().data;
				globalVariables.user.data = new AccountModel().data;
				globalVariables.cart.data.account_id = null;
				this.runMiddlewares();
				EventBus.publish("LOGOUT");
			});
		});

		askLogoutEventBus = EventBus.subscribe("ASK_TO_LOGOUT", () => {
			const attrs = {
				color: "button",
				class: "white--text",
				loading: false,
				"data-test-selector": "modal_sign_out_confirm_btn",
			};
			this.$modal.show(
				this.$i18n.t("modal.signOut.title"),
				this.$i18n.t("modal.signOut.content"),
				[
					{
						text: this.$i18n.t("btn.signOut"),
						attrs,
						events: {
							click: () => {
								attrs.loading = true;
								attrs.disabled = true;
								const wasB2b = this.is_b2b;
								new EComService().logout().finally(() => {
									attrs.loading = false;
									attrs.disabled = false;
									this.$root.user.data = new AccountModel().data;
									globalVariables.user.data = new AccountModel().data;
									globalVariables.cart.data.account_id = null;
									this.runMiddlewares();
									this.$modal.hide();
									if(wasB2b) {
										// Refresh website data without b2b
										EventBus.publish("B2B_LOGGIN_CHANGE");
									}
									EventBus.publish("LOGOUT");
								});
							},
						},
					},
					{
						text: this.$i18n.t("btn.cancel"),
						attrs: {
							text: true,
							"data-test-selector": "modal_sign_out_cancel_btn",
						},
						events: {
							click: () => {
								this.$modal.hide();
							},
						},
					},
				],
				{ maxWidth: 350, persistent: true }
			);
		});

		polyfillCountryFlagEmojis();

		b2bLoginEvent = EventBus.subscribe("B2B_LOGGIN_CHANGE", () => {
			this.load();
			if(this.$route.name !== "home") {
				this.$router.push('/');
			}

			// Refresh cart with correct salesgroup
			new EComService().createCart().then((cart) => {
				Sentry.configureScope(scope => {
					scope.setTag('cart_id', cart.data.id);
					scope.setExtras({ cartData: cart.data });
				});
				globalVariables.cart = cart;
				EventBus.publish('CART_UPDATED', cart);
			}).catch(error => {
				this.errors(error);
			})
		});
	},

	destroyed() {
		openPageEventBus.unsubscribe();
		addToCardEventBus.unsubscribe();
		askLogoutEventBus.unsubscribe();
		logOutUser.unsubscribe();
		b2bLoginEvent.unsubscribe();
	},

	watch: {
		cookieConsent: function (newValue, oldValue) {
			if(newValue && newValue !== oldValue) {
				const cookieConsent = JSON.parse(newValue);
				if(cookieConsent['tracking'] && cookieConsent['functionality']) {
					const gtmTags = ["GTM-KZZJHDK"];

					if (this.$root.websiteConfig.data.fields.google_tag_manager) {
						gtmTags.push(
							this.$root.websiteConfig.data.fields.google_tag_manager
						);
					}

					Vue.use(VueGtm, {
						id: gtmTags,
						queryParams: {},
						defer: false,
						compatibility: true,
						enabled: true,
						debug: false,
						loadScript: true,
					});
					clearInterval(this.interval);
				} else if (cookieConsent['tracking'] == false && cookieConsent['functionality'] == false) {
					clearInterval(this.interval);
				}
			}
		},
		$route: {
			immediate: true,
			handler() {
				this.$vuetify.goTo("#app");
				this.update();
			},
		},
	},
});
</script>
