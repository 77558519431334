<template>
	<div class="products-time-picker">
		<h4 class="mb-2">{{ productName }}</h4>
		<div class="select-button d-flex flex-column"
			 :class="[
				isPanelOpen ? 'rounded-t-lg' : 'rounded-lg',
				highlight ? 'highlight' : '',
			  ]"
			 :style="{ 'outline-color': highlight  ? `${primaryColor}` : ''}"
			@click="togglePanel">
			<div :class="'d-flex justify-space-between align-center w-100' + (date ? '': ' v-card--disabled')" >
				<span v-if="!date">{{ $t('product.select_date') }}</span>
				<span v-else-if="!selectedTime">{{ $t('product.select_time') }}</span>
				<span v-else>{{ selectedTime }}</span>
				<v-icon>{{ isPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
			</div>
		</div>

		<div v-if="isPanelOpen"
			ref="selectionPanel"
			class="selection-panel rounded-b-lg"
			:style="{ height: panelHeight + 'px', width: '100%' }">

			<v-skeleton-loader
				v-if="loading"
				type="list-item"
				class="my-4"
				:width="'100%'"
				:height="100"
			/>
			<template v-else>
				<v-row v-for="(slot, index) in timeSlots"
					:key="index"
					class="time-slot-item"
					@click="selectTime(slot)">
					<v-col col="5" class="pa-0 font-weight-bold time-range">{{ slot.period }}</v-col>
					<v-col col="1" v-if="!isForTimeBasedCombo" class="pa-0 text-start price">{{ $options.filters.currencyWithoutCountry(slot.price) }}</v-col>
					<v-col col="6" class="pa-0 text-end places-left">{{ slot.places }} {{ $t('product.places_left') }}</v-col>
				</v-row>
			</template>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import {EComService} from '@connectngo/sdk';
	export default {
		name: "ProductsTimePicker",
		props: {
			product: {
				type: Object,
				required: true
			},
			date: {
				type: String,
			},
			productsSelected: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				isPanelOpen: false,
				selectedTime: null,
				panelHeight: 0,
				timeSlots: []
			};
		},
		computed: {
			eventGroupId() {
				return this.product?.data?.event_group_id ;
			},
			productName() {
				return this.$options.filters.translatable(
					this.product?.data?.name,
					this.product?.data?.name_i18n,
					this.$i18n.locale
				);
			},
			highlight() {
				return !this.isPanelOpen && !this.selectedTime && this.date;
			},
			primaryColor() {
				return this.$vuetify.theme.isDark
					? this.$vuetify.theme.themes.dark.primary
					: this.$vuetify.theme.themes.light.primary
			},
			isForTimeBasedCombo() {
				return this.comboProductsForEventGroup.length > 0
			},
			comboProductsForEventGroup() {
				return this.productsSelected.filter(({ product }) => product.data.is_combo
					&& product.data.combo_products.some(comboProduct => comboProduct.event_group_id === this.product.data.event_group_id && comboProduct.id === this.product.data.id));
			}
		},
		mounted() {
			if(this.date) {
				this.fetchAvailabilities();
			}
		},
		watch : {
			date() {
				this.selectedTime = null;
				this.fetchAvailabilities();
			}
		},
		methods: {
			fetchAvailabilities() {
				this.loading = true;
				const startDate = moment(this.date).startOf('day').unix();
				const endDate = moment(this.date).endOf('day').unix();

				new EComService().getEventAvailabilities(this.eventGroupId, startDate, endDate, this.product.data.id)
					.then(response => { this.formatDataForSelect(response)})
					.catch(error => this.$handleError(this, error))
					.finally(() => (this.loading = false));
			},
			formatDataForSelect(datas) {
				this.timeSlots = [];
				const targetEventGroupId = this.product.data.event_group_id;
				const targetProductId = this.product.data.id;
				const requiredSharedQuantity = this.getEventSharedQuantity(targetProductId, targetEventGroupId)

				datas.forEach(( eventAvailability ) => {
					if (eventAvailability.data.day === this.date) {
						eventAvailability.data.events.forEach(( event ) => {
							if (event.data.available < requiredSharedQuantity) {
								return;
							}
							this.timeSlots.push({
								period: event.data.period,
								places: event.data.available,
								price: event.data.price?.min_price,
								start_ts: event.data.start_ts,
								end_ts: event.data.end_ts,
								event_id : event.data.id
							});
						});
					}
				});
				if (this.timeSlots.length === 1) {
					this.selectTime(this.timeSlots[0]);
				}
			},
			togglePanel() {
				if (!this.date) {
					return;
				}
				if (this.isPanelOpen) {
					this.isPanelOpen = false;
					this.panelHeight = 0;
				} else {
					this.isPanelOpen = true;
					this.$nextTick(() => {
						const panel = this.$refs.selectionPanel;
						this.panelHeight = panel.scrollHeight;
					});
				}
			},
			selectTime(slot) {
				this.selectedTime = slot.period;
				this.isPanelOpen = false;
				this.panelHeight = 0;

				this.validateEventPrice(slot);
			},
			validateEventPrice(slot) {
				//todo adjust this for the combo time selection
				// TODO : Check what we need to adjust ? If it's for the add to cart ? (Data is already prepared)
				new EComService().getProductPrices(slot.event_id, [this.product.data.id])
					.then(res => {
						this.$emit("timeSelected", {
							period: slot.period,
							places: slot.places,
							start_ts: slot.start_ts,
							end_ts: slot.end_ts,
							event_id: slot.event_id,
							price : res.data[this.product.data.id]?.min_price
						});
					})
					.catch(err => this.$handleError(this, err))
			},
			getEventSharedQuantity(productId, eventGroupId) {
				let requiredSharedQuantity = this.productsSelected
					.filter(({ product }) => product.data.event_group_id === eventGroupId && product.data.id === productId)
					.reduce((sum, product) => sum + product.quantity, 0);

				if (this.isForTimeBasedCombo) {
					this.comboProductsForEventGroup.forEach(product => {
						const combosForEventGroup = product.product.data.combo_products
							.filter(comboProduct => comboProduct.event_group_id === eventGroupId && comboProduct.id === productId)

						combosForEventGroup.forEach(combo => {
							const comboQty = product.product.data.combo_quantities
								.find(quantity => quantity.product_id === combo.id).quantity
							requiredSharedQuantity += combo.quantity * comboQty;
						})
					})
				}

				return requiredSharedQuantity;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.products-time-picker {
		position: relative;
		width: 100%;
	}
	.select-button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 16px;
		border: 1px solid $grey-light;
		cursor: pointer;
		background-color: white;
	}
	.selection-panel {
		background-color: white;
		overflow: hidden;
		transition: height 0.3s ease-out;
		border: 1px solid $grey-light;
		border-top: none;
		height: 0;
		width: 100%;
	}
	.selection-panel.pt-3 {
		padding-top: 16px;
	}
	.time-slot-item {
		display: flex;
		justify-content: space-between;
		padding: 8px 16px;
		cursor: pointer;
		margin: 0;
	}
	.time-slot-item:hover {
		background-color: #f0f0f0;
	}
	.places-left {
		color: gray;
		font-size: 0.9rem;
	}
	.highlight {
		outline-style: solid;
		outline-width: 2px;
		transition: outline 0.1s ease;
	}
</style>
