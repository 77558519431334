import { render, staticRenderFns } from "./CheckBoxTermsConditions.vue?vue&type=template&id=86de0492&scoped=true&"
import script from "./CheckBoxTermsConditions.vue?vue&type=script&lang=js&"
export * from "./CheckBoxTermsConditions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86de0492",
  null
  
)

export default component.exports