<template>
	<v-card :class="['pa-6 pt-8 card-container', 'rounded-lg', { 'no-border': isInModal }]" flat>
		<v-row :no-gutters="isInModal">
			<v-col cols="12"
				:md="isInModal ? '12' : '5'"
				class="d-flex align-self-stretch">
				<v-img
				:src="productImg"
				alt="Image"
				:aspect-ratio="16/9"
				class="rounded-lg"
				></v-img>
			</v-col>
			<!-- Modal only title-->
			<v-card-title
				class="text-left pa-0 mt-5 mb-4 text-blackish text-h6 title-text"
				v-if="isInModal">
				{{ productTitle }}
			</v-card-title>
			<v-col cols="12"
					v-if="isInModal && hasDescription()"
					md="12"
					class="d-flex align-self-stretch">
				<p v-html="getDescription()"></p>
			</v-col>
			<v-col cols="12"
					:md="isInModal ? 12 : 7"
					:class="{'modal-subcard pa-5' : isInModal}"
					class="d-flex flex-column">
				<v-card-title
					class="text-left pa-0 text-blackish text-h6 title-text">
					{{ productTitle }}
				</v-card-title>

				<v-row v-if="isInModal" class="pa-2 d-flex align-start mt-1">
					<v-col :cols="isMobile ? 12 : 6" >
						<QuantityPicker
							:current-quantity="currentQuantity"
							:min-quantity="product.data.min_quantity"
							:max-quantity="product.data.max_quantity"
							@quantityChanged="emit"/>
					</v-col>
					<v-col :cols="isMobile ? 12 : 6" class="pt-1 text-right d-flex align-center justify-end">
							<span v-if="hasCalendar && !isCombo" class="mr-2 text-caption text-grey-dark opacity-50">{{ $t('product.starting_from') }} :</span>
							<span class="text-h6 text-grey-dark">{{  $options.filters.currencyWithoutCountry(product.data.price) }}</span>
					</v-col>
				</v-row>

				<v-card-text
					v-if="truncatedDescription && !isInModal"
					class="text-left description-text pa-0 ma-0" v-html="truncatedDescription">
				</v-card-text>

				<QuantityPicker
					v-if="!isInModal"
					:current-quantity="currentQuantity"
					:min-quantity="product.data.min_quantity"
					:max-quantity="product.data.max_quantity"
					align="right"
					class="mt-auto quantity-picker"
					@quantityChanged="emit"/>

				<ProductCombos v-if="isCombo && isInModal" :product="product" />
			</v-col>
		</v-row>
		<!-- Button and Price section -->
		<v-row class="mt-6" v-if="!isInModal">
			<v-col cols="12" md="5" v-if="!isMobile">
			</v-col>
			<v-col cols="12" md="7">
				<v-row>
					<v-col
						v-if="!isIpad"
						cols="12"
						md="auto"
						class="">
						<v-btn
							@click="openModal"
							outlined
							class="elevation-0 text-caption border-sm rounded-md open-modal-button">
							{{ $t('product.find_out_more') }}
						</v-btn>
					</v-col>
					<v-col cols="12" md="auto" class="d-flex align-center justify-end md-justify-start ml-auto">
						<span v-if="hasCalendar && !isCombo" class="mr-2 text-caption text-grey-dark opacity-50">
							{{ $t('product.starting_from') }} :
						</span>
						<span class="text-h6 text-grey-dark">
							{{ $options.filters.currencyWithoutCountry(product.data.price) }}
						</span>
					</v-col>
					<v-col
						v-if="isIpad"
						cols="12"
						md="auto"
						class="d-flex justify-center ml-auto open-modal-button-container">
						<v-btn
							@click="openModal"
							outlined
							class="elevation-0 text-caption border-sm rounded-md w-100 open-modal-button">
							{{ $t('product.find_out_more') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- Modifiers -->
		<v-skeleton-loader v-if="loadingModifiers" type="list-item" />
		<ProductModifiers
			v-for="modifier in requiredModifiers"
			v-if="modifier.price"
			:modifier="modifier"
			:requiredModifiers="true"
			:productsSelected="productsSelected"
			:product="product"
			@quantityModifierChanged="quantityModifierChanged"/>

		<hr class="mt-6" v-if="requiredModifiers.length > 0"/>
		<p class="text-blackish text-bold mt-6" v-if="nonRequiredModifiers.length > 0">You may also like this option:</p>

		<ProductModifiers
			v-for="modifier in nonRequiredModifiers"
			v-if="modifier.price"
			:modifier="modifier"
			:requiredModifiers="false"
			:productsSelected="productsSelected"
			:product="product"
			@quantityModifierChanged="quantityModifierChanged"/>
		<!-- Modifiers Component -->
		<!-- COMBOS-->
		<ProductCombos v-if="isCombo && !isInModal" :product="product" />
	</v-card>
</template>

<script>
	import BackgroundImage from '@/assets/images/background.svg'
	import { ProductModel, EComService } from '@connectngo/sdk'
	import Text from '@/utils/Text'
	import QuantityPicker from '@/components/Shared/QuantityPicker'
	import ProductModifiers from '@/components/ProductV2/ProductModifiers'
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import ProductCombos from '@/components/ProductV2/ProductCombos.vue'

	export default {
		name: "ProductCard",
		components: { ProductCombos, QuantityPicker, ProductModifiers },
		mixins : [WindowWidthMixin],
		props: {
			product: {
				type: ProductModel,
				required: true
			},
			productsSelected: {
				type: Map,
				required: true
			},
			isInModal: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				loadingModifiers: true,
				modifiers: []
			}
		},
		mounted() {
			this.getModifiers();
		},
		computed: {
			requiredModifiers() {
				return this.modifiers.filter(modifier => modifier.required === true);
			},
			nonRequiredModifiers() {
				return this.modifiers.filter(modifier => modifier.required === false);
			},
			hasCalendar() {
				return this.product.data.event_group_id !== null || this.isTimeBasedCombo
			},
			isTimeBasedCombo() {
				return this.product.data.is_combo && this.product.data.combo_products.some(combo => combo.event_group_id)
			},
			currentQuantity() {
				return this.productsSelected.get(this.product.data.id)?.quantity || 0;
			},
			productTitle() {
				return this.$options.filters.translatable(this.product.data.name, this.product.data.name_i18n, this.$i18n.locale)
			},
			truncatedDescription() {
				const maxLength = this.isIPad ? 60 : 80;
				const description = this.getDescription();
				if (!description) {
					return null;
				}

				return description.length > maxLength ?
					Text.trimNotWithinWords( description, maxLength) + "..." : description;
			},
			productImg() {
				return this.product.data.image || BackgroundImage;
			},
			isCombo() {
				return this.product.data.is_combo && this.product.data.combo_products && this.product.data.combo_products.length > 0;
			}
		},
		watch: {
			requiredModifiers() {
				if(this.requiredModifiers.length > 0) {
					this.requiredModifiers.forEach(modifier => {
						// TODO what is the quantity for a required modifier ??
						// nothing in the mockups
						this.quantityModifierChanged(modifier, 1);
					});
				}
			}
		},
		methods: {
			getModifiers() {
				new EComService().getProductModifiers(this.product.data.id)
				.then(response => {
					const responseData = response;
					responseData.data.event_groups.forEach((item, index) => {
						item.data.modifiers.forEach((modifier) => {
							if (modifier.data) {
								this.modifiers.push(modifier.data);
							}
						});
					});
				})
				.catch((error) => this.$handleError(this, error))
				.finally(() => (this.loadingModifiers = false));
			},
			hasDescription() {
				const descriptions = this.product.data.description_i18n;
				const locale = this.$i18n.locale;

				return Boolean(descriptions?.[locale]);
			},
			getDescription() {
				if (this.hasDescription()) {
					const description =  this.$options.filters.translatable(
						'',
						this.product.data.description_i18n,
						this.$i18n.locale
					);

					return !this.isInModal ? description?.replace(/<\/?[^>]+(>|$)/g, "") : description;
				}
			},
			openModal() {
				this.$emit('showProductDetailModal', this.product);
			},
			quantityModifierChanged(modifier, newQuantity) {
				this.$emit("quantityModifierChanged", this.product, modifier, newQuantity);
			},
			emit(newQuantity) {
				this.$emit("quantityChanged", this.product, newQuantity);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.text-blackish {
		color: $blackish;
	}
	.text-bold {
		font-weight: 600;
	}
	.card-container {
		border : 1px solid $light-grey;
	}
	.text-blackish {
		color: $grey-dark;
	}
	.text-grey-light {
		color: $grey-light;
	}
	.description-text {
		color: $secondary;
		font-weight: 600;
	}
	.no-border {
		border: none;
	}
	.modal-subcard {
		border : 1px solid $light-grey;
		border-radius: 8px;
	}
	.open-modal-button {
		font-size: 0.95rem !important;
	}
	hr {
		border-top:1px dotted $blackish;
	}
	@media (max-width: 1024px) {
		.quantity-picker {
			margin-top: 30px !important;
			margin-right: 10px !important;
		}
		.open-modal-button-container {
			width: 100% !important;
			margin-left: 0 !important;
		}
		.open-modal-button {
			width: 100% !important;
		}
	}
	@media (max-width: 600px) {

		.open-modal-button {
			width: 100%;
		}
	}
</style>
