<template>
	<v-btn
		class="ma-4 pa-0 deleteBtn"
		color="error"
		width="'10px'"
		height="'12px'"
		icon
		@click.stop.prevent="handleRemoveClick()">
		<v-icon class="pa-0" color="#EF8686">mdi-delete</v-icon>
	</v-btn>
</template>

<style scoped>
	.deleteBtn {
		width: 10px !important;
		height: 12px !important;
		margin: 0 10px !important;
	}

	.deleteBtn i {
		width: 10px !important;
		height: 12px !important;
		padding: 0 !important;
	}
</style>

<script>
	export default {
		name: "RemoveButton",
		components: {},
		props: {
			paymentId: {
				type: Number,
				default: null,
			},
			emitValue: {
				type: String,
				default: '',
			}
		},
		data: () => ({}),
		watch: {},
		computed: {},
		methods: {
			handleRemoveClick() {
				this.$emit(this.emitValue);
			},
		},
	}
</script>
