<template>
	<v-container class="py-5 px-5">
		<h2 class="mb-5">{{ $t('cart.reload.title') }}</h2>
		<v-card flat>
			<v-container>
				<v-row class="d-flex my-sm-4 align-content-md-center">
					<v-col class="d-flex justify-md-center" lg="4" md="5" sm="4" cols="12" offset-lg="2"  offset-md="1">
						<v-img :src="tag?.reloadable_image" contain :max-height="isMobile ? 200 : undefined"/>
					</v-col>
					<v-col cols="12" lg="4" md="5" sm="8" class="align-content-center">
						<h2 class="mb-3">
							{{$options.filters.translatable($t('reloadTag.default_title'), tag.reloadable_title_i18n, $i18n.locale)}}
						</h2>
						<p>
							{{$options.filters.translatable($t('reloadTag.default_description'), tag.reloadable_description_i18n, $i18n.locale)}}
						</p>
						<p class="mt-8 mb-2 text-capitalize-fl">{{ $t('reloadTag.uid_number',{ media: mediaName}) }}</p>

						<v-combobox v-if="showComboBox"
							ref="comboBox"
							@change="handleComboboxChange"
							@keyup.enter="confirm"
							:search-input.sync="searchUid"
							v-model="uid"
							:items="userReloadWallets"
							:error-messages="uidError"
							:readonly="loading"
							:disabled="loading"
							:loading="loading"
							outlined
							clearable
							dense
							autofocus/>
						<v-text-field v-else
							@keydown.enter="confirm"
							v-model="uid"
							:error-messages="uidError"
							:readonly="loading"
							:disabled="loading"
							:loading="loading"
							autofocus
							dense
							outlined
							clearable/>
						<v-row>
							<v-col cols="12" md="6">
								<v-btn :disabled="loading" block outlined type="submit" @click="goBack">
									<span v-text="$t('btn.back')"></span>
								</v-btn>
							</v-col>
							<v-col cols="12" md="6">
								<v-btn :disabled="loading" :loading="loading" block color="primary" @click="confirm">
									<span v-text="$t('btn.confirm')"></span>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-container>
</template>

<script>

import Page from '@/components/Page.vue'
import { EComService, EventBus } from '@connectngo/sdk'
import globalVariables from '@/global'
import ReloadUidPicker from '@/components/ReloadV2/ReloadUidPicker.vue'
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin';

export default {
	name: 'ReloadUid',
	mixins: [ReloadV2Mixin],
	components: { Page, ReloadUidPicker},
	props: {
		tag: {
			type: Object,
			required: true,
		},
		mediaName : {
			type : String,
			required : true
		},
		isMobile : {
			type : Boolean,
			default : false
		}
	},
	data () {
		return {
			loading: false,
			uid: null,
			uidError: null,
			wallet : null,
			reloadProducts : [],
			userReloadWallets: [],
			searchUid : null,
		}
	},
	created () {
		this.checkReloadWalletOnCart()
	},
	mounted() {
		this.loading = true;
        this.getUserWallets()
	},
	watch: {
		'$root.user.data': {
		handler(newVal) {
			this.getUserWallets()
		},
		deep: true,
		},
	},
	computed : {
		uidInputVal() {
			if(this.showComboBox) {
				return this.uid?.value || this.searchUid
			} else {
				return this.uid;
			}
		},
		showComboBox() {
			return this.userReloadWallets.length > 0;
		}
	},
	methods: {
		getUserWallets() {
			this.fetchUserReloadWallets()
				.then(res => {
					this.userReloadWallets = this.formatWalletsSelectData(res.data.data, this.mediaName, false);
				})
				.catch(err => {
					this.$handleError(this, err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		goBack () {
			return this.$router.push({ name: 'home'})
		},
		async confirm () {
			if(this.loading) {
				return;
			}

			this.uidError = null
			this.loading = true
			try {
				await new EComService().getWalletFromUid(this.uidInputVal);
				let cart = await new EComService().attachReloadWalletToCart(this.$root.cart.data.uuid, this.uidInputVal);
				const products = await new EComService().getReloadTagProducts(this.tag.slug, cart.data.uuid)
				this.reloadProducts = products.data.products;

				if (this.reloadProducts.length > 0 ) {
					globalVariables.cart = cart
					EventBus.publish('CART_UPDATED', cart)
					this.$emit('uidProductsUpdated', this.reloadProducts)
					this.$emit('walletUpdated', cart.data.reload_wallet)
					this.loading = false

					if (this.$gtm) {
						dataLayer.push({ ecommerce: null });
						dataLayer.push({
							event: "reload_uid_validation",
							ecommerce: {
								reload_uid: this.uidInputVal
							},
						});
					}
				} else {
					// No product available for this wallet type
					this.uidError = this.$t('reloadTag.valid_uid_no_product_available',{ media: this.mediaName})
					await this.detachWallet();
					this.loading = false;
				}
			} catch (err) {
				if (err.response?.status === 404) {
					if (err.error !== 'Resource not found') {
						this.uidError = this.$t('reloadTag.invalid_uid',{ media: this.mediaName})
						if( this.$root.cart.data.reload_wallet) {
							try {
								await this.detachWallet();
							} catch (e) {
								this.$handleError(this, e)
							}
						}
					} else {
						this.uidError = this.$t('reloadTag.something_went_wrong');
					}
				} else {
					this.uidError = err.message
					this.$handleError(this, err)
				}
				this.loading = false;
			}
		},
		checkReloadWalletOnCart() {
			if(this.$root.cart.data.reload_wallet_id) {
				this.uid = this.$root.cart.data.reload_wallet.current_uid.uid;
			}
		},
		async detachWallet() {
			const cart = await new EComService().detachReloadWalletToCart(this.$root.cart.data.uuid);
			globalVariables.cart = cart
			EventBus.publish('CART_UPDATED', cart)
			this.$emit('uidProductsUpdated', null)
			this.$emit('walletUpdated', cart.data.reload_wallet)
		},
		handleComboboxChange() {
			this.$nextTick(() => {this.$refs.comboBox.isMenuActive = false})
			if (this.uid) {
				this.confirm();
			}
		}
	},
}
</script>
<style scoped>
	::v-deep .v-subheader {
		font-size: 1rem;
		font-weight: bold;
	}
</style>
