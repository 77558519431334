<template>
	<div class="d-print-none" v-resize="onResize">
		<component
  			v-if="ffCombo && combos.length > 0"
  			:is="isTimeBasedCombos ? 'ComboModalExperimental' : 'ComboModal'"
  			:showComboModal="showComboModal"
  			:combos="combos"
  			@handleComboModalCloseClick="handleComboModalCloseClick"
		/>
		<v-app-bar :height="height" :class="!is_b2b ? 'secondaryLight' : 'blackHeader'" v-bind="$attrs" v-on="$listeners" flat>
			<div class="w-100">
				<v-expand-transition>
					<v-sheet v-if="special" ref="special" color="secondaryDark" class="mx-n4" - shimsdark>
						<v-container class="d-flex align-center py-1" fluid>
							<v-spacer style="flex: 1" />
							<div class="text-center font-weight-bold" style="flex: 5">VENTE ! Passe de saison à 50% de rabais, seulement aujourd'hui!</div>
							<div class="d-flex align-center justify-end" style="flex: 1">
								<v-btn small icon @click="handleCloseSpecialClick">
									<v-icon class="ma-0" small>mdi-close</v-icon>
								</v-btn>
							</div>
						</v-container>
					</v-sheet>
				</v-expand-transition>
				<v-expand-transition>
					<v-sheet v-if="_heading" ref="heading" color="secondaryDark" dark class="mx-n4">
						<v-container>
							<v-row>
								<v-col cols="6" md="3" class="d-flex align-center py-2">
									<v-btn :href="$root.websiteConfig.data.fields.main_website_site_url || $root.websiteConfig.data.host" text small>
										<v-icon left x-small>mdi-chevron-left</v-icon>
										<span v-text="$t('toolbar.backToWebsite')"></span>
									</v-btn>
								</v-col>
								<v-col cols="6" class="d-none d-md-flex align-center py-2 justify-center text-center" v-if="false">
									<v-btn :href="$root.websiteConfig.data.fields['experiential-domain']" target="_blank" text small>
										<span v-text="$t('toolbar.visitExperientialSite')"></span>
									</v-btn>
								</v-col>
								<v-col cols="6" md="3" class="d-flex align-center py-2 justify-end">
								</v-col>
							</v-row>
						</v-container>
					</v-sheet>
				</v-expand-transition>
				<v-container class="d-flex align-center px-0">

					<v-btn data-testid="sideDrawer" icon @click="drawer = !drawer">
						<v-icon>mdi-menu</v-icon>
					</v-btn>

					<v-btn data-testid="homeBtn" :to="{ name: 'home' }" height="50" class="pa-0" text>
						<img :src="$root.websiteConfig.data.images['logo-header']" height="40" style="max-width: 10rem" alt="Logo" />
					</v-btn>

					<v-spacer></v-spacer>

					<v-menu :disabled="inCartPageData" v-model="cartSummaryMenu" open-on-hover open-delay="600" :close-on-content-click="true" transition="slide-y-transition" max-width="300" min-width="300" nudge-left="125" bottom offset-y>
						<template #activator="{ on, attrs }">
							<v-btn data-testid="cartBtn" v-bind="attrs" v-on="on" class="mr-2" icon>
								<v-badge color="error" :value="$root.cart.data.itemCount > 0" :content="itemCount">
									<v-icon>mdi-cart</v-icon>
								</v-badge>
							</v-btn>
						</template>
						<v-card>
							<v-list max-height="300" class="overflow-auto">
								<v-list-item v-if="loadingCartSummary" text>
									<v-progress-linear color="secondaryDark" indeterminate></v-progress-linear>
								</v-list-item>
								<template v-else>
									<v-alert v-if="cart?.data?.items?.length === 0" class="mb-0 text-center">
										<span v-text="$t('toolbar.cartEmpty')"></span>
									</v-alert>
									<v-list-item v-else :key="item.data.id" v-for="item in cart?.data?.items">
										<v-list-item-avatar>
											<v-img :src="item.data.product.data.image"></v-img>
										</v-list-item-avatar>
										<v-list-item-title>
											{{ item.data.quantity }}x
											<span v-text="$options.filters.translatable(item.data.product.data.name, item.data.product.data.name_i18n)"></span>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-list>
							<v-list v-if="!loadingCartSummary">

								<v-list-item v-if="ffCombo && combos.length > 0">
									<v-btn color="success" block @click.prevent="handleComboModalOpenClick">
										<ComboNotification :height="36" :border-radius="8" color="transparent" :handle-modal="false" @click.prevent/>
									</v-btn>
								</v-list-item>

								<v-list-item>
									<v-btn color="button" block :to="{ name: 'cart' }">
										<span v-text="$t('btn.viewCart')"></span>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card>
					</v-menu>

					<v-menu v-model="sessionPanelVisible" max-width="400" offset-y left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn data-testid="account" v-bind="attrs" v-on="on" :icon="$root.user.data.id === null || $vuetify.breakpoint.smAndDown" :text="$root.user.data.id !== null">
								<template v-if="$root.user.data.id && !$root.user.data.is_guest">
									<v-avatar data-testid="avatar" size="32" color="secondaryDark">
										<v-img :src="$root.user.data.image"></v-img>
									</v-avatar>
									<span v-if="$vuetify.breakpoint.mdAndUp" class="ml-3" v-text="$options.filters.shortUserName($root.user)"></span>
								</template>
								<v-icon v-else>mdi-account</v-icon>
							</v-btn>
						</template>
						<v-list data-testid="accountOptions" v-if="$root.user.data.id && !$root.user.data.is_guest" :dense="$vuetify.breakpoint.smAndDown" rounded>
							<v-list-item class="text-center py-4">
								<v-list-item-avatar :size="64">
									<v-avatar :size="64" color="secondaryLight">
										<v-img :src="$root.user.data.image"></v-img>
									</v-avatar>
								</v-list-item-avatar>
								<v-list-item-title class="text-uppercase">
									<span v-text="$t('toolbar.welcome', {
										name: $root.user.data.firstname
									})"></span>
								</v-list-item-title>
							</v-list-item>
							<v-list-item color="secondaryDark" v-for="(link, linkIdx) in menuLinks" :key="linkIdx" v-bind="link" v-on="link.events" :class="link.classes">
								<v-list-item-icon>
									<v-icon v-text="link.icon"></v-icon>
								</v-list-item-icon>
								<v-list-item-title v-text="$t('accountMenu.' + link.key)"></v-list-item-title>
							</v-list-item>
						</v-list>
						<v-card v-else color="secondaryLight">
							<h3 class="font-weight-regular text-center pt-8" v-text="$t('loginForm.connectToAccount')"></h3>
							<KYC ref="kyc" background-color="secondaryLight" register-page />
						</v-card>
					</v-menu>

					<div class="ml-2" v-if="showLanguageSwitcher">
						<LanguageSwitcher v-if="$vuetify.breakpoint.mdAndUp" v-model="$i18n.locale" text />
					</div>
				</v-container>
			</div>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" width="270" :right="isRtl" fixed temporary>

			<template #prepend>
				<v-list-item class="py-4 d-flex align-center">
					<div style="flex: 1">
						<v-btn @click.prevent="$vuetify.goTo('#app')" text x-large>
							<img :src="logo" style="height: 2.5rem; max-width: 8rem" alt="Logo" />
						</v-btn>
					</div>
					<v-btn @click.prevent="drawer = false" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-list-item>

				<v-list-item>
					<v-row>
						<v-col class="d-flex align-center" style="flex: 1">
							<span v-text="$t($root.user.data.id && !$root.user.data.is_guest ? 'toolbar.welcomeUser' : 'toolbar.welcome', {
								name: $root.user.data.firstname
							})"></span>
						</v-col>
						<v-col v-if="$vuetify.breakpoint.smAndDown" class="d-flex align-center" style="flex: 0">
							<LanguageSwitcher v-model="$i18n.locale" btn-class="px-0" color="secondaryDark" outlined small />
						</v-col>
					</v-row>
				</v-list-item>

				<v-sheet v-if="$vuetify.breakpoint.smAndDown" class="mt-4 mx-4">
					<template v-if="$root.user.data.id">
						<v-btn class="flexible" color="secondaryDark" outlined block :to="{ name: 'account' }">
							<v-icon color="secondaryDark" left>mdi-account-outline</v-icon>
							<span v-text="$t('accountMenu.account')"></span>
						</v-btn>
						<v-btn v-if="$root.websiteConfig.data.experiential_module" color="secondaryDark" class="mt-2 flexible" outlined block :href="$root.websiteConfig.data.fields['experiential-domain']" target="_blank">
							<v-icon color="secondaryDark" left>mdi-star-outline</v-icon>
							<span v-text="$t('accountMenu.experiential')"></span>
						</v-btn>
					</template>
					<template v-else>
						<v-btn class="flexible" color="secondaryDark" outlined block :to="{ name: 'login' }">
							<p class="text-wrap" style="width: 80%; margin: 0 auto; text-align: center">
								<v-icon left>mdi-login-variant</v-icon>
								<span v-text="$t('loginForm.loginAccount')"></span>
							</p>
						</v-btn>
					</template>
				</v-sheet>

				<v-divider class="mt-4"></v-divider>
			</template>

			<Categories v-model="navigableTags" :skeleton="$root.tags === null" dense />

			<div class="pa-4">
				<v-list class="pa-0" dense>
					<v-list-item
						class="mx-n4"
						:key="linkIdx"
						v-for="(link, linkIdx) in links"
						:to="(link.to) ? link.to : ''"
						:href="(link.href) ? link.href : ''"
					>
						<v-list-item-content>
							<v-list-item-title v-text="link.label"></v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon x-small>mdi-arrow-right</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
			</div>

			<template #append>
				<v-divider></v-divider>
				<div class="pa-4">

					<v-btn :href="$root.websiteConfig.data.fields.main_website_site_url || 'https://' + $root.websiteConfig.data.host" class="mb-4" text block small>
						<v-icon left x-small>mdi-chevron-left</v-icon>
						<span v-text="$t('toolbar.backToWebsite')"></span>
					</v-btn>

					<div v-if="$root.user.data.id && !$root.user.data.is_guest" class="mb-4">
						<v-btn color="secondaryDark" block text @click="handleLogoutClick">
							<v-icon color="secondaryDark" left>mdi-logout-variant</v-icon>
							<span v-text="$t('accountMenu.logout')"></span>
						</v-btn>
					</div>

					<div v-if="socialLinks.length > 0" class="d-flex align-center justify-center">
						<v-btn :key="linkIdx" v-for="(link, linkIdx) in socialLinks" :href="link.href" target="_blank" icon>
							<v-icon color="secondaryDark" v-text="link.icon"></v-icon>
						</v-btn>
					</div>

					<div class="caption opacity-75 text-center mt-2" v-text="$t('footer.copyright', {
						company: $options.filters.translatable($root.websiteConfig.data.name, $root.websiteConfig.data.name_i18n, $i18n.locale),
						year,
					})"></div>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import Categories from '@/components/Categories.vue';
import KYC from '@/components/KYC.vue';
import { EComService, CartModel, EventBus, PaginationModel } from '@connectngo/sdk';
import logo from "@/assets/images/logo_dark.svg";
import ComboNotification from '@/components/ComboNotification'
import ComboModal from '@/components/Modals/ComboModal'
import ComboModalExperimental from '@/components/Modals/ComboModalExperimental'
import globalVariables from '@/global'

export default {
	name: 'Toolbar',

	props: {
		is_b2b: {
			type: Boolean,
			default: false
		},
		special: {
			type: Boolean,
			default: false,
		},
		heading: {
			type: Boolean,
			default: false,
		},
		year: {
			type: Number,
			default: new Date().getFullYear(),
		},
		company: {
			type: String,
			default: 'Connect&Go',
		},
		links: {
			type: Array,
			default: () => ([])
		},
		socialLinks: {
			type: Array,
			default: () => ([])
		},
	},

	components: {
		LanguageSwitcher,
		Categories,
		KYC,
		ComboNotification,
		ComboModal,
		ComboModalExperimental
	},

	data: () => ({
		height: 64,
		drawer: false,
		sessionPanelVisible: false,
		cartSummaryMenu: false,
		loadingCartSummary: false,
		cart: new CartModel(),
		combos: [],
		comboUpdatedEventBus: null,
		showComboModal: false,
		ffCombo: false,
		inCartPageData: false,
	}),

	computed: {
		_heading() {
			return this.$root.websiteConfig.data.host && this.heading;
		},
		_combos() {
			return this.combos.map(combo => ({
				...combo,
				name: combo.data.parent_product.data.name,
			}))
		},
		isTimeBasedCombos() {
			const { feature_flags } = this.$root.websiteConfig.data || {};
			return feature_flags?.includes('co-5754-time-based-combos') && feature_flags?.includes('co-5739-cart-enhancement');
		},
		navigableTags() {
			let tags = (this.$root.tags || new PaginationModel()).results.filter(result => {
    			return result.data.navigable;
  			});

  			return new PaginationModel(tags);
		},
		logo() {
			return this.$root.websiteConfig.data.images['logo-header']
				? this.$root.websiteConfig.data.images['logo-header']
				: logo;
		},
		showLanguageSwitcher() {
			return this.$root.websiteConfig.data.tenant.locales.length > 1
		},
		itemCount() {
			let itemCount = 0;
			this.$root.cart.data.items.forEach(element => {
				itemCount += element.data.quantity;
			});
			return itemCount
		},
		isRtl() {
			return false;
		},
		menuLinks(){
			let links = this.$root.accountLinks;

			links = links.filter(link => {
				if(link.key === 'reschedule' && this.$root.websiteConfig.data.fields.reschedule === '0'){
					return;
				}

				return link;
			})

			return links;
		},
	},


	created() {
		this.inCartPage();
		this.combos = this.$root.combos;
		this.ffCombo = globalVariables.websiteConfig.featureFlagEnabled('combo-v2');
	},
	mounted() {
		this.height = this.getHeight();
		if (this.ffCombo) {
			let ref = this;
			this.comboUpdatedEventBus = EventBus.subscribe('COMBO_UPDATED', function (combos) {
				ref.combos = combos;
			});
		}
	},
	destroyed () {
		if (this.ffCombo) {
			this.comboUpdatedEventBus.unsubscribe()
		}
	},

	methods: {
		handleLogoutClick() {
			EventBus.publish('ASK_TO_LOGOUT');
		},
		handleCloseSpecialClick() {
			this.special = false;
		},
		onResize() {
			this.height = this.getHeight();
		},
		getHeight() {
			let total = this.$vuetify.breakpoint.mdAndUp ? 72 : 64;
			if (this.special) {
				total += this.$refs.special ? this.$refs.special.$el.offsetHeight : 36;
			}
			if (this._heading) {
				total += this.$refs.heading ? this.$refs.heading.$el.offsetHeight : 44;
			}
			return total;
		},
		loadCartSummary() {
			this.loadingCartSummary = true;
			return new EComService().getCart()
				.then(cart => this.cart = cart)
				.catch(reason => this.$handleError(this, reason))
				.finally(() => this.loadingCartSummary = false);
		},
		handleComboModalOpenClick() {
			this.showComboModal = true;
		},
		handleComboModalCloseClick() {
			this.showComboModal = false;
		},
		inCartPage(){
			this.inCartPageData = this.$router.currentRoute.name === 'cart';
		}
	},

	watch: {
		special() {
			this.height = this.getHeight();
		},
		heading() {
			this.height = this.getHeight();
		},
		sessionPanelVisible(visible) {
			if (visible && this.$refs.kyc) {
				this.$nextTick(() => {
					this.$refs.kyc.sessionPanel = 0;
				});
			}
		},
		cartSummaryMenu(visible) {
			if (visible) {
				this.loadCartSummary();
			}
		},
		$route (to, from){
        this.inCartPage();
    }
	}
}
</script>

<style lang="scss" scoped>
.v-btn--active::before {
	opacity: 0;
}

.blackHeader{
	background-color: #1E1E1E !important;
	:only-child{
		color:white;
	}
}
</style>
