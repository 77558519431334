<template>
	<v-checkbox
		v-model="readTerms"
		:disabled="loading"
		name="read_terms"
		class="mt-0"
		:rules="[rules.required]"
	>
		<template v-slot:label>
		<span>
			<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
			&nbsp;<a
			@click.prevent.stop="handleTermsClick"
			v-text="$t('paymentForm.creditBalanceDialog.terms')">
			</a>
		</span>
		</template>
	</v-checkbox>
</template>

<style scoped>

</style>

<script>
	import { Rules, EventBus } from "@connectngo/sdk";

	export default {
		name: "CheckBoxTermsConditions",
		props: {},
		data: () => ({
			loading: false,
			readTerms: false,
			rules: {},
		}),
		watch: {},
		computed: {},
		created() {
			this.rules = {
				required: (value) =>
					Rules.required(value) || this.$t("rules.required"),
			};
		},
		methods: {
			handleTermsClick() {
				EventBus.publish("OPEN_PAGE", "terms");
			},
		},
	};
</script>
